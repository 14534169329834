<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <div
      v-show="userDataInfo != null"
      style="font-size: 1em;"
      class="text-center bg-primary text-white"
    >
      Hello
      <strong
        >{{ userDataInfo ? userDataInfo.name : "N/A" }}
        {{ userDataInfo ? userDataInfo.surname : "N/A" }}</strong
      >, welcome to Portal.

      <h5 class="muted text-white">{{ dateNow }}</h5>
      <b-card class="mt-1 text-center">
        <div class="d-flex justify-content-center">
          
            <div
              class="d-flex flex-column align-items-center text-dark mr-4"
              style="cursor:pointer;"
              @click="requestModal"
              v-if="$Can('dashboard_pending_timeoff')"

              
            >
              <feather-icon icon="CalendarIcon" class="text-dark" size="20"  />
              <small>Time Off Requests</small>

              
            </div>

         
          
            <div
              class="d-flex flex-column align-items-center text-dark mr-4"
              style="cursor:pointer;"
              @click="pendingModal"
              v-if="$Can('dashboard_request_timeoff')"


            >
              <b-img src="@/assets/images/Calendar.svg" width="30px" />
              <small>Add Time Off</small>
            </div>

            <span> </span>
          
          
            <div
              class="d-flex flex-column align-items-center text-dark mr-4"
              style="cursor:pointer;"
              @click="fileModal"
              v-if="$Can('dashboard_files')"


            >
              <feather-icon icon="FileIcon" size="20" />
              <small>Company Files</small>
            </div>
          
          </div>
      </b-card>
    </div>

    <b-row cols="12" class="mt-2" v-if="$Can('dashboard_task')"
>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div v-if="userDataInfo">
          <dashboard-user-tasks :data="userDataInfo" v-if="userDataInfo.tasksList.length>0"></dashboard-user-tasks>
        </div>
      </b-col>
    </b-row>

    <b-modal id="request-modal" :no-close-on-backdrop="true" centered title="Request Time Off">
      <dashboard-timeoff-request :user-data-info.sync="userDataInfo" @check-user="checkUser"></dashboard-timeoff-request>

    </b-modal>

    <b-modal id="file-modal" :no-close-on-backdrop="true" centered title="Company Files">
      <dashboard-files :user-data-info.sync="userDataInfo"></dashboard-files>

    </b-modal>

    <b-modal id="pending-modal" :no-close-on-backdrop="true" centered title="Pending Requests">
      <dashboard-time-off :user-data-info.sync="userDataInfo" :annual-leave.sync="annualLeave" :sick-leave.sync="sickLeave" :unpaid-leave.sync="unpaidLeave"  @check-user="checkUser"></dashboard-time-off>

    </b-modal>

    <!-- <b-row cols="12" class="mt-2">
      <b-col cols="12" xl="4" lg="4" md="4" sm="12">
        
        <div v-if="userDataInfo">
         <dashboard-files :user-data-info.sync="userDataInfo"></dashboard-files>
        </div>
    
          </b-col>
                <b-col cols="12" xl="4" lg="4" md="4" sm="12">
        
    <div v-if="userDataInfo">
     <dashboard-time-off :user-data-info.sync="userDataInfo" :annual-leave.sync="annualLeave" :sick-leave.sync="sickLeave" :unpaid-leave.sync="unpaidLeave"  @check-user="checkUser"></dashboard-time-off>
    </div>

      </b-col>
      <b-col cols="12" xl="4" lg="4" md="4" sm="12">
        
        <div v-if="userDataInfo">
         <dashboard-timeoff-request :user-data-info.sync="userDataInfo" @check-user="checkUser"></dashboard-timeoff-request>
        </div>
    
          </b-col>


    </b-row> -->
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import homeStoreModule from "./homeStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import DashboardTimeOff from "./DashboardTimeoff.vue";
import DashboardFiles from "./DashboardFiles.vue";
import DashboardTimeoffRequest from "./DashboardTimeoffRequest.vue";
import { integer } from "vee-validate/dist/rules";
import DashboardUserTasks from "./DashboardUserTasks.vue";
import { VBPopover } from 'bootstrap-vue';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

    AppTimeline,
    AppTimelineItem,
    DashboardTimeOff,
    DashboardFiles,
    DashboardTimeoffRequest,
    DashboardUserTasks,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = "home";
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.checkUser();
  },

  data() {
    const dateNow = moment(new Date()).format("DD/MM/YYYY");

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      userDataInfo: null,
      timeOffData: [],
      annualLeave: 0,
      sickLeave: 0,
      unpaidLeave: 0,
    };
  },
  directives: {
    'b-popover': VBPopover,
  },


  methods: {
    fileModal() {
      this.$bvModal.show('file-modal');
    },
    requestModal() {
      this.$bvModal.show('request-modal');
    },
    pendingModal() {
      this.$bvModal.show('pending-modal');
    },

    kFormatter,

    checkUser() {
      this.formShow = true;

      this.userId = parseInt(this.userData.id);

      store
        .dispatch("home/fetchUser", { id: this.userId })
        .then((response) => {
          this.userDataInfo = response.data;
          this.userDataInfo.timeOffData.forEach((element) => {
            const specificDateParts = element.date.split("-");

            const currentYear = new Date().getFullYear();

            const isSameYear =
              parseInt(specificDateParts[2]) == parseInt(currentYear);

            if (
              element.type == "Annual Leave" &&
              element.status == "B" &&
              isSameYear
            ) {
              this.annualLeave += 1;
            } else if (
              element.type == "Sick Leave" &&
              element.status == "B" &&
              isSameYear
            ) {
              this.sickLeave += 1;
            } else if (
              element.type == "Unpaid Leave" &&
              element.status == "B" &&
              isSameYear
            ) {
              this.unpaidLeave += 1;
            }
          });

          if (response.data.status != "A") {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );
            localStorage.removeItem("userData");
            router.push({ name: "auth-login" });
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
