<template>
  <b-overlay>
    <b-col>
      <div>
        <b-form>
          <b-card
            
            style="border:1px solid gray; overflow-x: auto;"
          >
          <div class="d-flex justify-content-center align-items-center">
            <h4>
              Request Time off
            </h4>
          </div>
            <div>
              <div class="text-center">
                <h5>Annual Leave Used: <strong > {{ annualLeave }} days  </strong> Sick Leave Used:  <strong >  {{ sickLeave }} days </strong> Unpaid Leave Used:  <strong > {{ unpaidLeave }} days  </strong> </h5>
        
                <b-button
                  variant="primary"
                  class="btn btn-sm"
                  @click="timeOffAdd()"
                  >Request Time Off</b-button
                >
              </div>
            </div>

            <div>
              <b-table
                style="min-height: 250px;"
                ref="refUserListTable"
                :items="userDataInfo.timeOffData"
                filter-debounce="250"
                responsive
                :small="true"
                head-variant="light"
                :fields="tableColumns"
                :current-page="currentPage"
                :per-page="5"
                show-empty
                empty-text="No matching records found"
              >
                <template #cell(date)="data"> {{ data.value }} </template>

                <template #cell(type)="data">
                  <b-badge
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                  >
                    {{ data.value }}
                  </b-badge>
                </template>

                <template #cell(status)="data">
                  <b-badge
                    v-if="data.value == 'A'"
                    pill
                    :variant="`light-secondary`"
                    class="text-capitalize"
                  >
                    Pending
                  </b-badge>

                  <b-badge
                    v-if="data.value == 'B'"
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                  >
                    Approved
                  </b-badge>

                  <b-badge
                    v-if="data.value == 'C'"
                    pill
                    :variant="`light-danger`"
                    class="text-capitalize"
                  >
                    Refused
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="14"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="approveTimeOff(data)">
                            <feather-icon icon="CheckIcon" />
                            <span class="align-middle ml-50">Approve</span>
                          </b-dropdown-item>

                          <b-dropdown-item @click="refuseTimeOff(data)">
                            <feather-icon icon="XIcon" />
                            <span class="align-middle ml-50">Refuse</span>
                          </b-dropdown-item>


                    <b-dropdown-item @click="deleteTimeOff('R', data)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>

     
              <div class="mx-2 mb-2">
                <b-row class="d-flex justify-content-center align-items-center">
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      style="width:50%;"
                      v-model="currentPage"
                      :total-rows="userDataInfo.timeOffData.length"
                      :per-page="5"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="10" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="10" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-form>
      </div>

      <b-modal
        id="modal-time-off"
        cancel-variant="outline-secondary"
        ok-title="Schedule"
        @hidden="resetModalTimeOff"
        @ok="handleOkTimeOff"
        cancel-title="Close"
        :no-close-on-backdrop="true"
        centered
        :title="timeOffType + 'Time Off Request'"
      >
        <b-form ref="timeOffRules">
          <b-form-group
            :state="timeOffTypeState"
            label="Time Off Type"
            label-for="type"
            invalid-feedback="Please select the time off type"
          >
            <validation-provider
              name="Time Off Type"
              #default="{ errors }"
              rules="required"
            >
              <v-select
                v-model="timeOffType"
                :options="['Annual Leave', 'Sick Leave', 'Unpaid Leave']"
                label="type"
                :clearable="false"
                input-id="type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :state="timeOffDateState"
            invalid-feedback="Please select the dates"
            label-for="Time Off Dates"
          >
            <validation-provider
              name="Time Off Dates"
              #default="{ errors }"
              rules="required"
            >
              <flat-pickr
                placeholder="Time Off Dates"
                v-model="timeOffDate"
                class="form-control"
                :config="{ mode: 'multiple', dateFormat: 'd-m-Y' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </b-modal>
    </b-col>
  </b-overlay>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import {
  BModal,
  BAvatar,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BLink,
  BButton,
  BAlert,
  BCardHeader,
  BCardTitle,
  VBToggle,
  BTabs,
  BTab,
  BCardText,
  BProgress,
  BTable,
} from "bootstrap-vue";
import moment from "moment";
import homeStoreModule from "./homeStoreModule.js";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";

// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';

// import { avatarText } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BAvatar,
    BModal,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BTabs,
    BCardText,
    BTab,
    BProgress,
    vSelect,
    flatPickr,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    userDataInfo: {
      type: Object,
      required: true,
    },

    annualLeave: {
      type: Number,
      required: true,
    },
    sickLeave: {
      type: Number,
      required: true,
    },
    unpaidLeave: {
      type: Number,
      required: true,
    },
  },

  data: function() {
    return {
      required,
      alphaNum,
      email,

      baseURL: store.state.app.baseURL,

      formShow: false,

      startDate: null,
      endDate: null,
      rangeDate: null,

      timeOffDate: null,
      timeOffType: null,
      currentPage: 1,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: "% ",
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      config: {
        dateFormat: "d-m-Y",
        mode: "range",
        locale: { firstDayOfWeek: 1 },
      },

      tableColumns: [
        { key: "date", sortable: true, class: "text-center" },
        { key: "type", sortable: true, class: "text-center" },
        { key: "status", sortable: true, class: "text-center" },
        { key: "actions", sortable: true, class: "text-center" },
      ],

      teamTableColumns: [
        { key: "id", sortable: true, class: "text-center" },
        { key: "title", sortable: true, class: "text-center" },
        { key: "foreman", sortable: true, class: "text-center" },
        { key: "staff_count", sortable: true, class: "text-center" },
        { key: "day_rate", sortable: true, class: "text-center" },
        { key: "status", sortable: true, class: "text-center" },
      ],
    };
  },

  created() {},

  methods: {

    
    approveTimeOff(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .post(`approveTimeOff`, {id:data.item.id})
            .then((res) => {
              this.$emit("check-user");
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },


    refuseTimeOff(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, refuse it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .post(`refuseTimeOff`, {id : data.item.id})
            .then((res) => {
              this.$emit("check-user");
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },



    deleteTimeOff(val, data) {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteTimeOff/${data.item.id}`)
            .then((res) => {
              this.$emit("check-user");
            })
            .catch((error) => {
              console.log("error delete ", error);
            });
        }
      });
    },

    clearDate() {
      if (this.rangeDate != null && this.rangeDate != "") {
        this.startDate = null;
        this.endDate = null;
        this.rangeDate = null;
      }
    },

    dateFormat(val) {
      if (val != null) {
        moment.locale("en-US");
        return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "N/A";
      }
    },

    timeOffAdd() {
      this.$nextTick(() => {
        this.$bvModal.show("modal-time-off");
      });
    },

    handleOkTimeOff(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler

      this.modalTimeOff();
    },

    modalTimeOff(val) {
      if (!this.checkFormValidityTimeOff()) {
        return;
      }

      if (this.timeOffDate && this.timeOffType) {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-time-off");
        });

        this.formShow = true;

        var data = {
          dates: this.timeOffDate,
          type: this.timeOffType,
          user_id: this.userDataInfo.id,
        };

        axiosIns
          .post("bookTimeOff", data)
          .then((response) => {
            this.timeOffDate = null;
            this.timeOffType = null;
            if (response.data.length > 0) {
              let text = "The dates below are already booked for time-off.<br>";
              response.data.forEach((element) => {
                text +=
                  "<p>" + element[0].date + " - " + element[0].type + "</p>";
              });

              this.$swal({
                title: "Warning",
                html: text,
                icon: "warning",
                dangerMode: true,
                showCancelButton: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }

            this.formShow = false;
            this.$emit("check-user");
          })
          .catch((error) => {
            this.formShow = false;
            console.log(error);
          });
      }
    },

    resetModalTimeOff() {
      this.timeOffDate = null;
      this.timeOffType = null;
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    checkFormValidityTimeOff() {
      const timeValid = this.$refs.timeOffRules.checkValidity();

      return timeValid;
    },
  },

  computed: {
    timeOffDateState() {
      return this.timeOffDate != null ? true : false;
    },
    timeOffTypeState() {
      return this.timeOffType != null ? true : false;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>

<style scoped>
.toCapitalFirst {
  text-transform: capitalize;
}

.modern-table {
  width: 50%;
}

.modern-table th {
  padding: 5px;
  font-weight: bold;
  color: #7f7f7f;
}

.modern-table td {
  padding: 5px;
}

.emoji {
  font-size: 12px;
  vertical-align: middle;
}

.item-price {
  font-weight: bold;
  color: #7f7f7f;
}
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: "\2713";
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: "\2713";
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-90 {
  width: 90%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: "\23F7";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
