<template>
 <b-overlay>
  
  <b-col>
      <b-card
        style="border:1px solid gray; overflow-x: auto;"
      >
      <div class="d-flex justify-content-center align-items-center">
        <h4 class="mb-4">Company Files</h4>

      </div>

        <!-- user suggestion  -->
        <div style="min-height: 250px;">


          <div
            
            class="mt-2 mb-2 d-flex justify-content-between align-items-center"
          >
            <b-img
              src="@/assets/images/sharepoint.png"
              class="mr-50 "
              @click="goToLink(excelFileURL + data.fileLink)"
              style="cursor:pointer"
              width="30px"
            />
            <div class="user-page-info pr-1" style="width: 100%">
              <span
                @click="goToLink('https://tailormadeexcelltd.sharepoint.com/sites/GTMProjects/SitePages/ProjectHome.aspx/')"
                style="cursor:pointer; font-size:1.1em"
                class="mb-2"
                >Visit Sharepoint
              </span>
            </div>
            <b-button
              style="background-color:#139ca2 !important; border-color: unset !important;"
              target="_blank"
              href="https://tailormadeexcelltd.sharepoint.com/sites/GTMProjects/SitePages/ProjectHome.aspx/"
              class="btn-icon"
              size="sm"
            >
              <feather-icon
                icon="NavigationIcon"
                size="16"
                style="color:white"
              />
            </b-button>
            <hr />
          </div>


          <div
            v-for="(data, index) in paginatedData"
            :key="index"
            class="mt-2 mb-2 d-flex justify-content-between align-items-center"
          >
            <b-img
              src="@/assets/images/file.png"
              class="mr-50 "
              @click="goToLink(excelFileURL + data.fileLink)"
              style="cursor:pointer"
              width="30px"
            />
            <div class="user-page-info pr-1" style="width: 100%">
              <span
                @click="goToLink(excelFileURL + data.fileLink)"
                style="cursor:pointer; font-size:1.1em"
                class="mb-2"
                >{{ data.fileName }}
              </span>
            </div>
            <b-button
              variant="primary"
              target="_blank"
              :href="excelFileURL + data.fileLink"
              class="btn-icon"
              size="sm"
            >
              <feather-icon
                icon="ArrowDownIcon"
                size="16"
                style="color:white"
              />
            </b-button>
            <hr />
          </div>

          <div
            v-if="paginatedData.length == 0"
            class="d-flex justify-content-center align-items-center mt-1 mb-1"
          >
            No Files
          </div>
        </div>


        <div class="mx-2 mb-2">
                <b-row class="d-flex justify-content-center align-items-center">
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center"
                  >
              <b-pagination
                style="width:50%"
                v-model="currentPage"
                :total-rows="userDataInfo.internalFiles.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="10" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="10" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-overlay>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import {
  BCard,
  BCardBody,
  BButton,
  BCardHeader,
  BCardTitle,
  BTable,
  BPagination,
} from "bootstrap-vue";
import homeStoreModule from "./homeStoreModule.js";

import { required, alphaNum, email } from "@validations";

// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';

// import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BTable,
    BPagination,
  },


  props: {
    userDataInfo: {
      type: Object,
      required: true,
    },
  },

  data: function() {
    return {
      perPage: 5,
      currentPage: 1,
      totalRows: 0,

      perPageOptions: [10, 25, 50, 100],
      filter: null,
      loading: false,
      percentCompleted: 0,
      excelFileURL: store.state.app.excelFileURL,

      download: false,

      formShow: false,

      tableColumns: [
        {
          key: "id",
          sortable: true,
          class: "text-center",
          thStyle: "width: 5%",
        },
        {
          key: "fileName",
          sortable: true,
          class: "text-center",
          thStyle: "width: 30%",
        },
        {
          key: "actions",
          sortable: false,
          class: "text-center",
          thStyle: "width: 20%",
        },
      ],
    };
  },

  created() {},

  methods: {
    goToLink(data) {
      const link = document.createElement("a");
      link.href = data;
      link.target = "_blank";
      link.click();
    },
  },

  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.userDataInfo.internalFiles.slice(startIndex, endIndex);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>

<style scoped>
.toCapitalFirst {
  text-transform: capitalize;
}

.modern-table {
  width: 50%;
}

.modern-table th {
  padding: 5px;
  font-weight: bold;
  color: #7f7f7f;
}

.modern-table td {
  padding: 5px;
}

.emoji {
  font-size: 12px;
  vertical-align: middle;
}

.item-price {
  font-weight: bold;
  color: #7f7f7f;
}
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: "\2713";
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: "\2713";
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-90 {
  width: 90%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: "\23F7";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
